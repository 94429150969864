.slider__flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row !important;
}
.slider__img {
  width: 260px;
  height: auto;
  filter: grayscale(100%);
}

.marquee-container {
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 100vw !important;
}
.slider__img:hover {
  filter: grayscale(0%);
}

.content {
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.marquee {
  margin-top: 2vh;
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 290px;
  overflow: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 50s linear infinite;
}

.marquee2 {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 0;
  overflow: hidden;
  position: fixed;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;

  top: 97%;
  right: 50%;
  transform: translate(50%, -30%);
}

.track2 {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee2 32s linear infinite;
  /* animation-direction: alternate; */
}
@keyframes marquee2 {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
span {
}

@media screen and (max-width: 800px) {
  .marquee {
    margin-top: 0;
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 165px !important;
    overflow: hidden;
  }
  .slider__img {
    width: 160px;
    height: auto;
  }
}
@media screen and (max-width: 500px) {
  .marquee {
    margin-top: 0;
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 190px !important;
    overflow: hidden;
  }
  .slider__img {
    width: 160px;
    height: auto;
  }
}

@media screen and (width: 768px) {
  .marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 235px !important;
    overflow: hidden;
  }
  .slider__img {
    width: 180px;
    height: auto;
  }
}

@media screen and (height: 812px) {
  .marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 140px !important;
    overflow: hidden;
  }
  .slider__img {
    width: 100px;
    height: auto;
  }
}

@media screen and (width: 1024px) {
  .marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 270px !important;
    overflow: hidden;
  }
  .slider__img {
    width: 200px;
    height: auto;
  }
}

/*  */

@media screen and (height: 1024px) {
  .marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 25vh;
    overflow: hidden;
  }
}

@media screen and (max-width: 1400px) {
  .marquee {
    height: 292px;
  }
}

@media screen and (height: 1400px) {
  .marquee {
    height: 320px;
  }
}

@media screen and (min-width: 2880px) {
  .marquee {
    height: 350px;
  }
}
