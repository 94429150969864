.App {
  text-align: center;
}
body {
  background-color: rgb(0, 0, 0);
  overflow-x: hidden !important;
}

@media screen and (max-width: 800px) {
}
