@font-face {
  font-family: para;
  src: url(../assets/Linotype\ -\ NeuzeitSLTStd-Book.otf);
}
@font-face {
  font-family: plan;
  src: url(../assets/ITCAvantGardePro-Bold.otf);
}
.planman {
  color: #ffffff;
  font-size: 58px;
  font-weight: 700;
  text-align: left;
  line-height: 105%;
  font-family: plan, "sans-serif";
}
.bombzaway1 {
  width: 400px;
  height: auto;
  text-align: center;
  z-index: 10000000000 !important;
}
.plans {
  font-family: para, "sans-serif";
  color: #989599;
  font-size: 19px;
  text-align: left;
  line-height: 135%;
  margin-top: 25px;
}
.plansRed {
  font-family: para, "sans-serif";
  color: #d20c0f;
  font-size: 19px;
  text-align: left;
  margin-top: 25px;
}
.plansRed2 {
  font-family: para, "sans-serif";
  color: #d20c0f;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  margin-top: 25px;
}
.sleep {
  color: white;
  text-decoration: underline;
}
.monkeyR {
  position: absolute;
  right: 0;
  width: auto;
  z-index: 1 !important;
  height: 650px;
}
.monkeyL {
  position: absolute;
  left: 0;
  width: auto;
  z-index: 1 !important;
  height: 650px;
}
.planBkg {
  padding-top: 100px;
}

@media screen and (max-width: 800px) {
  .bombzaway1 {
    width: 100%;
    height: auto;
  }
  .monkeyR {
    display: none;
  }
  .monkeyL {
    display: none;
  }
  .plans {
    font-size: 2vh !important;
  }
  .plansRed {
    font-size: 2vh !important;
  }
  .plansRed2 {
    font-size: 2.5vh !important;
  }
  .plan__all {
    padding: 2rem;
  }
  .plan__grid {
    position: relative;
    bottom: 0vh;
  }
  .planman {
    text-align: center;
  }
  .planBkg {
    margin-bottom: 10vh;
  }
}

@media screen and (width: 1024px) {
  .planman {
    font-size: 5vh;
    width: 40vw;
  }
  .plans {
    font-size: 1.5vh !important;
  }
  .plansRed {
    font-size: 1.5vh !important;
  }
  .plansRed2 {
    font-size: 2vh !important;
  }
  .planBkg {
    position: relative;
    bottom: 1vh;
    padding: 0vh;
  }
  .monkeyR {
    position: absolute;
    right: 0;
    width: 36vw;
    top: 20vh;
    height: auto;
  }
  .monkeyL {
    position: absolute;
    left: 0;
    top: 20vh;
    width: 36vw;
    height: auto;
  }
}

@media screen and (max-width: 800px) and (max-height: 500px) {
  .plans {
    font-size: 3vh !important;
  }
  .plansRed {
    font-size: 3vh !important;
  }
  .plansRed2 {
    font-size: 3.5vh !important;
  }
}

@media screen and (max-width: 1400px) {
  .plans {
    font-size: 2vh !important;
  }
  .plansRed {
    font-size: 2vh !important;
  }
  .plansRed2 {
    font-size: 2.5vh !important;
  }
}
