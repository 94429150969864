@font-face {
  font-family: info;
  src: url(../assets/Sarah\ Script\ Font.ttf);
}
@font-face {
  font-family: coolest;
  src: url(../assets/ITCAvantGardePro-Bold.otf);
}
.leftText {
  text-align: right;
}
.rightText {
  text-align: left;
}
.head {
  text-align: center;
}
.text {
  font-family: info;
  font-size: 50px;
  color: white;
}
.head__and__text {
  position: relative;
  z-index: 1000000;
  bottom: 0;
}
.nav__disc__footer {
  background-color: white;
  border: 0.25vh solid black;
  border-radius: 3vw;
  color: black;
  font-weight: 700;
  font-size: 15px !important;
  width: 150px;
  height: auto;
  padding: 8px 0 8px 0;
}
.top__discord__footer {
  text-decoration: none;
}
.nav__disc__footer {
  background-color: black;
  color: white;
  border: 0.3vh solid white;
}
.nav__disc__footer:hover {
  background-color: white;
  color: black;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}
.join {
  color: #d20c0f;
  font-family: coolest, "sans-serif";

  position: relative;
  font-size: 35px;
}
.join2 {
}
.crew {
  position: relative;

  /* text-shadow: 2px 2px yellowgreen; */
}
.make2 {
  position: absolute;
  background-color: black;
  font-size: 100px;
  font-family: info;
  color: transparent;
  top: 0;
  width: 100%;
  z-index: -1;
  top: 3%;
  left: 52%;
  transform: translate(-50%, -50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 5px rgb(0, 0, 0);
}

.join_us_mint {
  position: relative;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -100%);
}
.make {
  color: white;
  position: relative;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  font-family: info;
  z-index: 100 !important;
}
.joinText {
  position: relative;
}
.social {
  display: flex;
  justify-content: space-between;
}
.social1 {
  position: relative;
}
.invite {
  position: absolute;
  top: 1vh;
  color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  font-size: 1.8vh !important;
}

.top__discordImg2 {
  width: 10vw;
  height: auto;
}
.social__img {
  width: 5vw;
  height: auto;
}
.social__img2 {
  width: 9vw !important;
  height: auto;
}
.info__bottom {
  position: relative;
  cursor: pointer;
  margin: 100px 0 100px 0;
}
.head__img {
  width: 70%;
  height: auto;
}
@media screen and (max-width: 800px) {
  .nav__disc__footer {
    position: relative;
    left: -1.8vw;
    border-radius: 5vw;

    font-weight: 700;
    font-size: 8px !important;
    width: 90px;
    height: auto;
    /* padding:8px 0 8px 0; */
  }
  .crew__img {
    width: 350px;
    height: auto;
  }
  .social__img {
    width: 100%;
    height: auto;
  }
  .sol {
    width: 10vw !important;
    height: auto;
  }
  .social__img2 {
    width: 100% !important;
    height: auto;
  }
  .invite {
    position: absolute;
    top: 1.5vh;
    color: white;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 700;
    font-size: 2vh !important;
  }

  .leftText {
    text-align: center;
  }
  .rightText {
    text-align: center;
  }
  .text {
    font-size: 3vh !important;
  }
  .join {
    font-size: 3vh;
  }
  .join2 {
    color: transparent;
    font-family: coolest, "sans-serif";

    font-size: 3vh;
    position: absolute;
    left: 0;
    right: 0.5vw;
    margin: 0 auto;
    top: -0.5vh;
    -webkit-text-stroke-width: 0.1vh;
    -webkit-text-stroke-color: yellowgreen;
  }
  .crew {
  }
  .crew2 {
    font-family: coolest, "sans-serif";

    position: absolute;
    font-size: 14vh;
    font-weight: 700;
    /* text-shadow: 2px 2px yellowgreen; */
    color: transparent;
    left: 0 !important;
    right: 1vw !important;
    margin: 0 auto;
    top: 3.1vh;
    -webkit-text-stroke-width: 0.3vh;
    -webkit-text-stroke-color: yellowgreen;
    text-shadow: none;
  }

  .make2 {
    position: absolute;
    background-color: black;
    font-size: 55px;
    font-family: info;
    color: transparent;
    top: 0;
    width: 100%;
    z-index: -1;
    top: 3%;
    left: 52%;
    transform: translate(-50%, -50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 5px rgb(0, 0, 0);
  }

  .join_us_mint {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  .make {
    color: white;
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 55px;
    font-family: info;
    z-index: 100 !important;
  }
  .info__bottom {
  }
  .head__img {
    width: 30vw;
    height: auto;
  }
  .head__and__text {
    position: relative;
    z-index: 1000000;
    bottom: 11vh;
  }
  .joinText {
    position: relative;
    top: -5vh;
  }
}

@media screen and (max-width: 1400px) {
  .social__img2 {
    width: 18vw;
    height: auto;
  }
  .invite {
    font-size: 1vh;
  }
  /* .nav__disc__footer{
  width: 12vw;
  height: auto;
} */
}

@media screen and (width: 1024px) {
  .make2 {
    position: absolute;
    background-color: black;
    font-size: 120px;
    font-family: info;
    color: transparent;
    top: 0;
    width: 100%;
    z-index: -1;
    top: 3%;
    left: 52%;
    transform: translate(-50%, -50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 5px rgb(0, 0, 0);
  }

  .join_us_mint {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  .make {
    color: white;
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 120px;
    font-family: info;
    z-index: 100 !important;
  }
  .invite {
    font-size: 1vh !important;
    top: 0.9vh;
  }

  .social__img {
    width: 100%;
    height: auto;
  }
  .sol {
    width: 10vw !important;
    height: auto;
  }
  .social__img2 {
    width: 100% !important;
    height: auto;
  }
  .leftText {
    text-align: right;
  }
  .rightText {
    text-align: left;
  }
  .text {
    font-size: 2vh !important;
  }
  .join {
    font-size: 3vh;
  }
  .join2 {
    color: transparent;
    font-family: coolest, "sans-serif";

    font-size: 3vh;
    position: absolute;
    left: 0;
    right: 0.5vw;
    margin: 0 auto;
    top: -0.5vh;
    -webkit-text-stroke-width: 0.1vh;
    -webkit-text-stroke-color: yellowgreen;
  }
  .crew {
  }
  .crew2 {
    font-family: coolest, "sans-serif";

    position: absolute;
    font-size: 18vh;
    font-weight: 700;
    /* text-shadow: 2px 2px yellowgreen; */
    color: transparent;
    top: 3vh;
    -webkit-text-stroke-width: 0.3vh;
    -webkit-text-stroke-color: yellowgreen;
    text-shadow: none;

    left: -0.7vw;
  }

  .info__bottom {
  }
  .head__and__text {
    position: relative;
    bottom: 7vh;
  }
  .text {
    font-size: 2.8vh !important;
  }
  .joinText {
    position: relative;
    bottom: 3vh;
  }
}

@media screen and (height: 812px) {
  .join2 {
    color: transparent;
    font-family: coolest, "sans-serif";

    font-size: 3vh;
    position: absolute;
    left: 0;
    right: 1vw !important;
    margin: 0 auto;
    top: -0.5vh !important;
    -webkit-text-stroke-width: 0.1vh;
    -webkit-text-stroke-color: yellowgreen;
  }
}

@media screen and (height: 1024px) {
  .join2 {
    color: transparent;
    font-family: coolest, "sans-serif";

    font-size: 3vh;
    position: absolute;
    left: 0;
    right: 0.5vw;
    margin: 0 auto;
    top: -0.4vh;

    -webkit-text-stroke-width: 0.1vh;
    -webkit-text-stroke-color: yellowgreen;
  }
  .crew2 {
    position: absolute;
    left: 0.9vw;
  }
}

@media screen and (width: 1366px), screen and (height: 1024px) {
  .join2 {
    color: transparent;
    font-family: coolest, "sans-serif";

    font-size: 8vh !important;
    position: absolute;
    left: 0;
    right: 0.5vw;
    margin: 0 auto;
    top: -0.8vh;

    -webkit-text-stroke-width: 0.1vh;
    -webkit-text-stroke-color: yellowgreen;
  }
}

@media screen and (width: 768px), screen and (height: 1024px) {
  .join2 {
    color: transparent;
    font-family: coolest, "sans-serif";

    font-size: 3vh !important;
    position: absolute;
    left: 0;
    right: 0.5vw;
    margin: 0 auto;
    top: -0.5vh;

    -webkit-text-stroke-width: 0.1vh;
    -webkit-text-stroke-color: yellowgreen;
  }
  /* .nav__disc__footer{
    width: 18vw !important;
    height: auto;
    font-size: 1.1vh !important;
  } */
}

@media screen and (width: 768px) {
  .invite {
    font-size: 1.5vh !important;
    top: 1vh;
  }

  .make2 {
    position: absolute;
    background-color: black;
    font-size: 70px;
    font-family: info;
    color: transparent;
    top: 0;
    width: 100%;
    z-index: -1;
    top: 3%;
    left: 52%;
    transform: translate(-50%, -50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 5px rgb(0, 0, 0);
  }

  .join_us_mint {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  .make {
    color: white;
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px;
    font-family: info;
    z-index: 100 !important;
  }
}

@media screen and (max-width: 360px) {
  /* .nav__disc__footer{
    background-color: white;
    border: 0.5vh solid black;
    border-radius: 5vw;
    color: black;
    font-weight: 700;
    font-size: 1.5vh !important;
    width: 30vw !important;
  height: auto;
  padding:1vh 0 1vh 0;
  
  } */
  .make2 {
    position: absolute;
    background-color: black;
    font-size: 40px;
    font-family: info;
    color: transparent;
    top: 0;
    width: 100%;
    z-index: -1;
    top: 3%;
    left: 52%;
    transform: translate(-50%, -50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 5px rgb(0, 0, 0);
  }

  .join_us_mint {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  .make {
    color: white;
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-family: info;
    z-index: 100 !important;
  }
}

@media screen and (max-width: 300px) {
  /* .nav__disc__footer{
    background-color: white;
    border: 0.5vh solid black;
    border-radius: 5vw;
    color: black;
    font-weight: 700;
    font-size: 1.5vh !important;
    width: 30vw !important;
  height: auto;
  padding:1vh 0 1vh 0;
  
  } */
  .make2 {
    position: absolute;
    background-color: black;
    font-size: 40px;
    font-family: info;
    color: transparent;
    top: 0;
    width: 100%;
    z-index: -1;
    top: 3%;
    left: 52%;
    transform: translate(-50%, -68%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 5px rgb(0, 0, 0);
  }

  .join_us_mint {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  .make {
    color: white;
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -70%);
    font-size: 40px;
    font-family: info;
    z-index: 100 !important;
  }
}
