@font-face {
  font-family: para;
  src: url(../assets/Linotype\ -\ NeuzeitSLTStd-Book.otf);
}
.footer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 6vw 2vh 6vw;
  font-family: para, "sans-serif";
  background-color: rgb(56, 56, 56);
}
.icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.solBottom {
  position: relative;

  width: 2.1vw;
  height: auto;
  cursor: pointer;
}
.copyright {
  font-size: 2vh;
}
.socialImg {
  width: 2.5vw;
  height: auto;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .socialImg {
    width: 5vw;
    height: auto;
  }
  .icons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 2vh;
  }
  .solBottom {
    width: 5vw !important;
    height: auto;
    margin-bottom: 0.5vh;
  }
}

@media screen and (width: 1024px) {
  .socialImg {
    width: 5vw;
    height: auto;
  }
}

@media screen and (width: 1400px) {
  .solBottom {
    top: 0vh;
  }
}

@media screen and (max-width: 800px) and (max-height: 500px) {
  .socialImg {
    width: 3vw;
    height: auto;
  }
}
