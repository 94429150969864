@font-face {
  font-family: info;
  src: url(../assets/Sarah\ Script\ Font.ttf);
}
@font-face {
  font-family: coolest;
  src: url(../assets/ITCAvantGardePro-Bold.otf);
}
@font-face {
  font-family: para;
  src: url(../assets/Linotype\ -\ NeuzeitSLTStd-Book.otf);
}
.gold {
  /* margin-top: 1vh; */
}
.gold__img {
  width: 59vw;
  height: auto;
}
.goldPart2 {
  padding: 80px 0 0 0;
  text-align: center;
}
.belowText {
  width: 100px !important;
  height: auto;
}
.collection {
  font-family: coolest, "sans-serif";

  color: #000;
  font-size: 58px;
  font-weight: 600;
  text-align: left;
  margin-top: 0;
  line-height: 105%;
  /* width: 20vw; */
}
.align__collection {
  text-align: left;
  font-size: 24px;
  color: #000 !important;
  font-family: para, "sans-serif";
}
.rightIcon {
  text-align: right;
  position: relative;
}
.upperText {
  color: white;
  font-size: 24px;
  text-align: left;
  font-family: coolest, "sans-serif";

  font-weight: 700;
}
.belowText {
  text-align: left;
  color: #989898;
  font-size: 14px;
  font-family: para, "sans-serif";
  padding: 2vh 2vh 2vh 0vh;
  line-height: 140%;
  font-weight: 800;
}
.bar {
  width: 2vw;
  height: 1vh;
  background: #d20c0f;
  transition: width 2s, height 4s;
  margin-bottom: 1vh;
}
.bar:hover {
  width: 100%;
}
.bar1 {
  width: 5vw;
  height: 1vh;
  background: #000000;
  transition: width 2s, height 4s;
}
.bar1:hover {
  width: 90%;
}
.eyes__info {
  padding: 0 !important;
}
.goldPart {
  padding-top: 100px !important;
}
.attributes {
  position: relative;
  top: 5vh;
  padding: 100px 10vw;
  margin-top: 50px !important;
}
.firstPart {
  padding: 0 0 0 15vw;
  position: relative;
}

.icon {
  width: 30vw;
  height: auto;
}
.rightIcon {
  text-align: center;
  padding: 10vw;
}
.solana__logo {
  margin-top: 50px;
  text-align: center;
}
.text:hover {
  color: #d20c0f;
}
.head__img {
  width: 330px;
  height: auto;
}
.head {
  width: 100%;
  text-align: center;
}

/* ------------------------------------------------------------------- */

@media screen and (max-width: 800px) {
  .head__img {
    width: 230px;
    height: auto;
  }
  .solpeepzText {
    padding-left: 15vw;
  }
  .head__and__text {
    margin-top: 100px !important;
  }
  .rightIcon {
    text-align: center;
  }
  .icon {
    width: 50vw !important;
    height: auto;
  }
  .upperText {
    font-size: 2.5vh;
    padding: 0;
  }
  .belowText {
    font-size: 1.4vh;
    padding: 0;
  }

  .icon {
    width: 100%;
    height: auto;
  }
  .collection {
    font-size: 6vh;
  }
  .align__collection {
    text-align: left;
    font-size: 18px;
    color: #000;
    font-family: para, "sans-serif";
  }
  .gold {
    background-image: linear-gradient(
      to bottom,
      #c4a253,
      #7a6633,
      #635228,
      #473b1d,
      #000000
    );
    background-size: cover;
    height: 100px;
  }
  .gold__img {
    width: 85vw;
    height: auto;
  }
  .attributes {
    position: relative;

    padding: 0 9vh 9vh 9vh;
  }
}

@media screen and (width: 1024px) {
  .solpeepzText {
    padding-left: 15vw;
  }
  .head__and__text {
    margin-top: 100px !important;
  }
  .gold {
    background-image: linear-gradient(
      to bottom,
      #c4a253,
      #7a6633,
      #635228,
      #473b1d,
      #000000
    );
    background-size: cover;
    height: 10vh;
  }
  .gold__img {
    width: 70vw;
    height: auto;
  }
  .collection {
    color: #000;
    font-size: 5vh !important;
    line-height: 5vh;
    font-weight: 600;
    /* width: 20vw; */
  }
  .rightIcon {
    text-align: center;
  }
  .icon {
    width: 50vw;
    height: auto;
  }
  .belowText {
    font-size: 1.7vh;
  }
  .attributes {
    position: relative;

    padding: 0 9vh 9vh 9vh;
  }
  .firstPart {
    margin-top: 50px !important;
  }
}
@media screen and (width: 375px) {
}
@media screen and (height: 812px) {
  .gold {
    background-image: linear-gradient(
      to bottom,
      #c4a253,
      #7a6633,
      #635228,
      #473b1d,
      #000000
    );
    background-size: cover;
    height: 7vh;
  }
  .gold__img {
    width: 68vw;
    height: 16vh !important;
  }
  .collection {
    font-size: 6vh;
  }
  .upperText {
    font-size: 2.5vh;
  }
}

@media screen and (height: 1024px) {
  .gold__img {
    width: 75vw;
    height: auto !important;
  }
  .collection {
    font-size: 6vh;
  }
}

@media screen and (max-width: 800px) and (max-height: 500px) {
  .gold {
    background-image: linear-gradient(
      to bottom,
      #c4a253,
      #7a6633,
      #635228,
      #473b1d,
      #000000
    );
    background-size: cover;
    height: 26vh;
  }
  .gold__img {
    width: 68vw;
    height: auto !important;
  }
  .goldPart {
    margin-top: 0;
  }
  .collection {
    font-size: 8vh;
  }
  .align__collection {
    font-size: 4vh;
  }
  .icon {
    width: 50vw !important;
    height: auto;
  }
  .upperText {
    font-size: 4vh;
  }
  .belowText {
    font-size: 2vh;
  }
  .text {
    font-size: 5vh !important;
  }
}

@media screen and (height: 1024px) {
  .upperText {
    font-size: 3vh;
  }
}

@media screen and (max-width: 1400px) {
  .text {
    font-size: 3.5vh;
  }
}
@media screen and (max-width: 500px) {
  /* .gold {
    margin-top: 5vh;
  } */
}
